/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Monstserrat', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/
#header {
  /*background-color: #1896FC;*/
  background-color: #ffffff;
  color: #343f52;
  font-size: .875rem;
}
#header a {
  color: #343f52;
}
#header a:hover {
  /*color: #FC1896;*/
  /*color: #00196E;*/
  color: #1896FC;
}
.navbar-brand-theme {
  padding-top: .1rem;
}
#footer {
  font-size: .75rem;
  /*background-color: #343a40;*/
  /*background-color: #57cbf4;
  color: #ffffff;*/
  /*bottom: 0;
  position: sticky;*/
}
body {
  font-family: Montserrat, sans-serif;
  color: #333333;
  /*background-color: #F3F4F6;*/
  background-color: #fcfcfc;
  /*font-size: .875rem;*/
}

h4 {
  color : #666666;
}

.mainApp {
  min-height: 100vh;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}

.chatApp {
  height: 100vh;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #cccccc;
  border-radius: 0.5rem;
  padding: 20px;
  /*margin-top: 20px;*/
  background-color: #ffffff;
}

.chatImage {
  max-height: 50vh;
}

.text-theme {
  color: #FC1896;
}
.btn-theme {
  
  /*background-color: #FC1896;
  border-color: #FC1896;*/
  color: #ffffff !important;
  background-color: #605dba !important;
  border-color: #605dba !important;
  
}
.btn-theme:hover {
  /*background-color: #c91378;
  color: #ffffff;
  border-color: #c91378;*/
  filter: brightness(85%) !important;
}
.btn-theme.disabled, .btn-theme:disabled {
  /*background-color: #b01069;
  color: #ffffff;
  border-color: #b01069;*/
  filter: brightness(75%) !important;
}

/*
 * header nav
 */
 .header-dropdown .dropdown-menu {
  border:1px solid rgba(0,0,0,0);
  /*background-color: #1896FC;*/
  font-size: .875rem;
}
.header-dropdown .dropdown-item {
  padding-left: 1.0rem;
  /*background-color: #57cbf4;*/
  /*background-color: #333333;*/
}

.public-component-parent {
  /*background-color: #1896FC;*/
  background-color: #fcfcfc;
  
  height:100vh;
}
.public-component {
  /*border:1px solid rgba(255,255,255,.5);*/
  border: 1px solid #cccccc;
  border-radius: 0.5rem;
  padding: 20px;
  margin-top: 20px;
  background-color: #ffffff;
}
/*
 * forms
 */
 .required-field {
  color: #ff0000;
}

/*
 * table sticky
 */
.table-sticky-container {
  overflow-y: auto;
  max-height: 500px;
}
.table-sticky-header th {
  position: sticky;
  /*top:0px;*/
  top:-1px;
}

/*heading subject*/
.head-subject {
  color: #666666;
  font-size: .9em;
}

/*code*/
.code-block {
  background-color: #202123;
  border-radius:8px;
}
.code-block code {
  color: #ffffff;
}

/*
 * Sidebar
 */
 #sidenav a {
  color: #343f52;
  font-weight: normal;
}
#sidenav a:hover {
  color: #1896FC;
}

.sidebar .navbar-toggler {
  padding:3px;
  margin-left:5px;
}
.sidebar .navbar-toggler-icon {
  width:20px;
  height:20px;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  /*padding: 48px 0 0;*/ /* Height of navbar */
  padding-top: 55px;
  padding-left: 5px;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  /*background-color: #697581 !important;*/
  /*background-color: #00196E;*/
  background-color: #ffffff;
}

.sidebar .dropdown-menu {
  /*background-color: #343a40;*/
  background-color: #697581;
  min-width:9rem;
  border:1px solid rgba(255,255,255,0);
  padding: 0.25rem 0;
  font-size: .75rem;
}
.sidebar .dropdown-item {
  padding-left: 1.0rem;
  background-color: #697581;
  /*background-color: #343a40;*/
}
.sidebar .dropdown {
  font-size: .75rem;
}
.sidebar-dropdown {
  background-color: #697581;
  min-width:9rem;
  border:1px solid rgba(255,255,255,0);
  padding: 0.25rem 0;
  font-size: .75rem;
}

.config-btn{
  font-size: 1.5rem;
  line-height: 1.25rem;
}

.sidebar-submenu {
  /*background-color: #1896FC;*/
  
}

/* reports */
.report-info {
  font-weight: 500;
}
.chart-card {
  border: 1px solid #cccccc;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff;
}
.chart-card-table {
  overflow: auto;
}
.chart-title {
  font-size: 1.4em;
  color: #666666;
}
.interaction-card {
  border: 1px solid #cccccc;
  padding: 10px;
  border-radius: 10px;
  background-color: #ffffff;
}
.onboard-card {
  border: 1px solid #cccccc;
  padding: 20px;
  border-radius: 10px;
  min-height: 250px;
  background-color: #ffffff;
}
.onboard-title {
  font-size: 1.4em;
  color: #666666;
}
.card-icon-detail {
  display: flex;
  column-gap: 20px;
}
.card-icon-detail-img {
  display: block;
  width:125px;
}
.card-icon-detail-img img {
  width:100%;
}
.card-icon-detail-text {
  display: block;
}

th {
  color: #666666;
  font-weight: 600;
}

/*
 * chat bubbles
 */
 .thread-area {
  /*display: inline;*/
  height:300px;
  display: flex;
  flex-direction: column-reverse;
 }
 .thread-area-all {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  /*overflow-y: auto;*/
  flex-direction: column-reverse;
 }
 .transcript-msg {
  margin-bottom:20px;
 }
 .transcript-role {
  color: #999999;
  /*font-weight: 500;*/
  /*margin-left:5px;*/
 }
 .transcript-finish {
  display: inline-block;
  position: relative;
  background-color: #ff4d4d;
  color:#ffffff;
  padding: 5px 10px;
  border-radius: 10px;
  margin-top: 20px;
  /*float:none;*/
  /*clear:both;*/
 }
 .interaction-date {
  font-weight: 500;
 }
 .interaction-tokens {
  color: #999999;
 }
 .interaction-meta {
  color: #999999;
 }
 .chat-bubble {
  display: inline-block;
  position: relative;
  padding: 10px;
  background-color: #e3e3e3;
  border-radius: 15px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
  /*margin-bottom: 20px;*/
 }
 .chat-system {
  background-color: #777777;
  color: #ffffff;
 }
 .chat-user {
  background-color: #147efb;
  color: #ffffff;
 }
 .chat-assistant {
  background-color: #e3e3e3;
  color: #333333;
 }
 .chat-assistant:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 15px;
  right: auto;
  top: auto;
  bottom: -15px;
  border: 8px solid;
  border-color: #e3e3e3 transparent transparent #e3e3e3;
 }
 .chat-user:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  right: 15px;
  left: auto;
  top: auto;
  bottom: -15px;
  /*border: 10px solid;*/
  border: 8px solid;
  border-color: #147efb #147efb transparent transparent ;
 }

 .chat-function {
  background-color: #333333;
  color: #ffffff;
 }
 .function-pre {
    white-space: pre-wrap;       
    white-space: -moz-pre-wrap;  
    white-space: -pre-wrap;      
    white-space: -o-pre-wrap; 
    word-wrap: break-word;
 }

 .chat-bubble-in {
  background-color: #147efb;
  color: #ffffff;
}
.chat-bubble-in.chat-dir:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 15px;
  right: auto;
  top: auto;
  bottom: -15px;
  border: 8px solid;
  border-color: #147efb transparent transparent #147efb;
}
.chat-bubble-out {
  background-color: #e3e3e3;
}
.chat-bubble-out.chat-dir:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  right: 15px;
  left: auto;
  top: auto;
  bottom: -15px;
  /*border: 10px solid;*/
  border: 8px solid;
  border-color: #e3e3e3 #e3e3e3 transparent transparent ;
}
.chat-meta {
  font-size: .750rem;
  color: #999999;
}
.chat-image {
  display: block;
  max-width: 250px;
  max-height: 250px;
  width: auto;
  height: auto;
}
.chat-card-title {
  font-weight: 600;
}

.chat-typing {
  display: block;
  /*width: 60px;
  height: 40px;
  border-radius: 20px;
  margin: 0 1rem;*/
  display: flex;
  justify-content: center;
  align-items: center;
}
.ct-circle {  
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #8d8d8d;
  margin: 3px;
}
.ct-circle.scaling {
    animation: typing 1000ms ease-in-out infinite;
    animation-delay: 3600ms;
}
.ct-circle.bouncing {
    animation: bounce 1000ms ease-in-out infinite;
    animation-delay: 3600ms;
}
.ct-circle:nth-child(1) {
  animation-delay: 0ms
}
.ct-circle:nth-child(2) {
  animation-delay: 333ms
}
.ct-circle:nth-child(3) {
  animation-delay: 666ms
}

@keyframes typing {
  0% {
    transform: scale(1)}
  33%{
    transform: scale(1)}
  50%{
    transform: scale(1.4)}
  100%{
    transform: scale(1)}
}
@keyframes bounce {
  0%{
    transform: translateY(0)}
  33%{
    transform: translateY(0)}
  50%{
    transform: translateY(-10px)}
  100%{
    transform: translateY(0)}
}
@media (max-width: 767.98px) {
  /*.card-icon-detail {
    display: block;
  }*/

  .sidebar {
    /*top: 5rem;*/
    top: auto;
    bottom:auto;
    left:auto;
    z-index: auto;
    padding:5px;
    position: relative;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

